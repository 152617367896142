<template>
  <div>
    <!--导航栏--->
    <div  style="z-index: 10;position: fixed;left: 0;right: 0;"> <van-nav-bar title="商品详情" left-arrow @click-left="$router.back(-1)" /></div>
    <!--导航栏--->
    <div style="height: 50px;"></div>
    <!-- 图片 -->
    <div class="111">
      <img src="https://img01.yzcdn.cn/vant/cat.jpeg" alt="">
    </div>
    <!-- 底部 -->
    <div>
      <van-cell is-link @click="showPopup">展示弹出层</van-cell>
      <van-popup v-model="show" closeable close-icon="close"  @click-left="$router.back(-1)" position="bottom" :style="{ height: '60%' }">
        <div>
          <div> <van-card  price="2.00" desc="库存1000个" title="请选择：颜色风格" thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"/>
          </div>
        </div>
         <div style="padding: 10px;" >
           <div>颜色</div>
           <div style="padding:5px;" @click="degl(x,y)"  >
             <van-button type="default" size="small" color="#acacac" name="1">白色</van-button>
             <van-button type="default" size="small" color="#acacac" style="margin-left:15px;" name="2">黑色</van-button>
           </div>
         </div>
          <div style="padding: 10px;">
           <div>风格</div>
           <div style="padding:5px;">
             <van-button type="default" size="small" color="#acacac" >简约</van-button>
             <van-button type="default" size="small" color="#acacac" style="margin-left:15px;">复杂</van-button>
           </div>
         </div>
         <div style=" display: flex; justify-content: space-between; padding: 10px; align-items: center;">
           <div>购买数量</div>
              <van-stepper v-model="value" />
         </div>
        <div @click="$router.push('/shoppingcart')">
        <van-goods-action>
          <van-goods-action-button type="warning" color="#ff6400" text="确定" />
        </van-goods-action>
       </div>
       </van-popup>
    </div>
    <!-- 底部 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
        chosenTag:1,
         show: true,
         value:1,
           radio: '1',
           x:null,
           y:null,

    };
  },
  created() {},
  mounted() {},
  methods: {
    degl(x,y){
      console.log(x,y);
    },
    //定义了点击关注  
			followCircle() {
				this.chosenTag = 1
        console.log(111);
			},
     showPopup() {
      this.show = true;
    },
     onClickLeft() {
    },
  },
  watch: {},
  computed: {},
};
</script>
<style>
.van-card__content{
      display: flex;
    flex-direction: column-reverse;
}

</style>